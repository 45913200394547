// @flow

import React from 'react'
import LazyImage from './LazyImage'
import LazySimpleImage from './LazySimpleImage'
import Appear from './Appear'
import { useLiterals } from 'store/'
import LinkExternalHP from './LinkExternalHP'
import makeLayer from '../lib/makeLayer'
import Html from './Html'

type Props = {
  item: object,
  darkBackground: boolean
}

const PrinterShoppableItem = ({
  item,
  darkBackground,
}: Props) => {

  if (!item) return null

  const { image, title, text, price, anchorPrice, inStock, buyURL, ref } = item
  
  return (
    <div className="flex flex-col h-full border border-grayer" data-json={JSON.stringify(item)}>
      <div>
        {image && image.lazy ? (
          <LazyImage image={image} alt={title} />
        ) : (
          <LazySimpleImage image={image} alt={title} />
        )}
      </div>
      <div className="flex flex-col flex-grow px-6 py-4">
        <h3 className="mb-8 font-bold leading-tight text-left text-big1"><Appear>{title}</Appear></h3>
        <span className="block mb-4 font-light leading-6"><Appear><Html text={text} /></Appear></span>
        <Appear className='flex flex-col justify-end flex-grow'>
          <div className="flex items-start justify-between text-greenPrice">
            <div>
              {anchorPrice && anchorPrice !== 'blank' ? <>
                {price && price !== 'blank' && <p className="font-light line-through text-larger text-gray">{price.replace('.000000', '')}</p>}
                <p className="inline-block text-4xl font-light text-primary text-greenPrice">{anchorPrice.replace('.000000', '.00')}</p>
              </> : <>
                {
                  price !== 'blank' && <p className="inline-block text-4xl font-light text-primary text-greenPrice">{price.replace('.000000', '.00')}</p>
                }
              </>}
            </div>
            {inStock && inStock.length > 0 ? <span className="font-light text-primary text-gray text-larger">{'in Stock'}</span> : <span/>}
          </div>
        </Appear>
        <Appear>
          <div className={`mt-8 ${darkBackground ? 'border border-white' : ''} `}>
            {buyURL && buyURL.length > 0 ? (
              <LinkExternalHP
                dataLayer={makeLayer({event: 'e_buyClick', linkPlacement: 'content',linkID: ref})}
                data-buyctaref={ref}
                data-buyctahpref={ref}
                className="inline-block w-full px-8 py-3 text-center text-white whitespace-no-wrap bg-black border border-black hover:bg-white hover:border-black hover:text-black"
                href={buyURL}
                target="_blank"
              >
                {item.buyText || useLiterals('buy_now')}
              </LinkExternalHP>
            ) : ( <></> )}
            {/* {ref && ref.length > 0 ? <p className="ml-2 font-light text-gray text-normal">{ref}</p> : <p/>} */}
          </div>
        </Appear>
      </div>
    </div>
  )
}

export default PrinterShoppableItem
