// @flow
import React from 'react'
import type { Module } from 'types/'

const components = {}

function importAll(r) {
  r.keys().forEach(key => {
    const filename = key.match(/([a-zA-Z0-9]+)\.js$/)
    if (filename) {
      components[filename[1]] = r(key).default
    }
  })
}

importAll(require.context('components', true))

const ModuleIterator = ({
  modules,
  parentName = '',
  pageName,
}: {
  modules: Array<Module>,
  parentName?: string,
  pageName?: Array<string>,
}): React$Element<React$FragmentType> => {
  return (
    <>
      {modules.map((m: Module, index: number) => {
        if (typeof components[m.module] === 'undefined') {
          return (
            <div
              className="hidden"
              key={typeof m.id !== 'undefined' ? m.id : index}
            >
              Module <strong>{m.module}</strong> not found
            </div>
          )
        }
        const isLeft = item =>
          ['PrinterInfo', 'CategorySuperWrapperMenu', 'StoryBlock1'].includes(
            item.module
          )
        const Component = components[m.module]
        const structure =
          m.structure && m.structure.length > 0
            ? m.structure.filter(item => !isLeft(item))
            : []
        const structureLeft =
          m.structure && m.structure.length > 0
            ? m.structure.filter(item => isLeft(item))
            : []
        structure.map((item, index) => {
          const nav = { prev: '', next: '' }
          if (typeof structure[index - 1] !== 'undefined') {
            nav.prev = structure[index - 1].module
          }
          if (typeof structure[index + 1] !== 'undefined') {
            nav.next = structure[index + 1].module
          }
          if (typeof item.options !== 'undefined') {
            item.options.nav = nav
          } else {
            item.options = {
              nav,
            }
          }
          return item
        })

        if (modules[index - 1]?.module === 'Menu') {
          m.options.childAfterMenu = true
        }

        return (
          <Component
            key={typeof m.id !== 'undefined' ? m.id : index}
            pttId={m.id}
            structure={m.structure}
            {...m.options}
            moduleName={m.module}
            moduleId={m.id}
            parentName={parentName}
            pageName={pageName}
            contentLeft={
              structureLeft.length > 0 && (
                <ModuleIterator parentName={m.module} modules={structureLeft} />
              )
            }
          >
            {structure.length > 0 && (
              <ModuleIterator parentName={m.module} modules={structure} />
            )}
          </Component>
        )
      })}
    </>
  )
}

export default ModuleIterator
