// @flow

import React from 'react'
import Html from './Html'
import Spacer from './Spacer'
import Container from './Container'
import Grid from './Grid'
import Appear from './Appear'

type Props = {
  text: string,
  title: string,
  paddingBottom?: Boolean
}

const StoryBlock3 = ({ text, title, paddingBottom = false }: Props) => {
  return (
    <Spacer top smaller bottom={paddingBottom}>
      <Container>
        <Appear>
          <Grid>
            <div className="col-span-4 col-start-1 md:col-start-3 md:col-span-6">
              <div className="mb-8 font-light text-big2">
                <Html text={text} />
              </div>
              <div>
                <span className="font-light">{title}</span>
              </div>
            </div>
          </Grid>
        </Appear>
      </Container>
    </Spacer>
  )
}

export default StoryBlock3
