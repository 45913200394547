// @flow

import React from 'react'
import Spacer from './Spacer'
import Container from './Container'
import Grid from './Grid'
import LazyImage from './LazyImage'
import LazySimpleImage from './LazySimpleImage'
import makeAssetUrl from '../lib/makeAssetUrl'
import type { ApiImage } from 'types/'

const StoryBlock4 = ({
  image,
  imageAlt,
  respectSize
}: {
  image: ApiImage,
  imageAlt: string,
  respectSize: boolean
}) => {
  return (
    <Spacer smaller>
      <Container>
        <Grid>
          <div
            className={`${
              respectSize ? 'col-span-4 md:col-span-3' : 'col-span-4 md:col-span-8'
            } col-start-1 md:col-start-3 mb-8 mt-10`}
            // } col-start-1 col-span-12 md:col-start-4 md:col-span-9 my-6`}
          >
            <div className='w-full h-full overflow-hidden'>
              {respectSize ? <LazySimpleImage image={makeAssetUrl({ asset: image, isImage: true })} alt={imageAlt} className='w-full h-full object-cover' /> :
              // {respectSize ? <LazySimpleImage image={makeAssetUrl({ asset: image, isImage: true, smallest: true })} alt={imageAlt} /> :
              <LazyImage image={image} alt={imageAlt} className='w-full h-full object-cover' />}
            </div>
          </div>
        </Grid>
      </Container>
    </Spacer>
  )
}

export default StoryBlock4
