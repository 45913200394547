// @flow
import React from 'react'
import Grid from './Grid'
import Spacer from './Spacer'
import Appear from './Appear'
import Container from './Container'
import Button from './Button'
import Html from './Html'

type Props = {
  title: string,
  text: string,
  ctaText: string,
  ctaUrl: string,
  type: string,
  postCategory: Record<string, string>,
}

const TitleText = ({ text, title }: { text: string, title: string }) => (
  <>
    <h4 className="my-4 text-xl font-bold text-center md:my-8">
      <Appear>
        <Html text={title} />
      </Appear>
    </h4>
    <div className="font-light text-center">
      <Appear>
        <Html text={text} />
      </Appear>
    </div>
  </>
)

const DesignJet8 = ({
  title,
  text,
  ctaText,
  ctaUrl,
  type,
  postCategory,
}: Props): React$Element<(_0: Props) => any> => {
  const isColorValid = color => {
    if (!color) return false
    return color.match(new RegExp('^#[0-9a-fA-F]{6}$'))
  }

  let content = (
    <Spacer top bottom>
      <Container className={`relative`}>
        <Grid>
          <div className={`col-span-4 col-start-1 md:col-start-5`}>
            <TitleText title={title} text={text} />
            <div className="flex justify-center mt-8">
              <Appear>
                <Button style="shop" title={ctaText} href={ctaUrl} />
              </Appear>
            </div>
          </div>
        </Grid>
      </Container>
    </Spacer>
  )

  if (type === 'post') {
    content = (
      <Spacer key="designJet8">
        <Container className={`relative`}>
          <Grid>
            <div
              className={`col-span-4 col-start-1 mb-12 mt-8 md:col-start-3 md:col-span-8 text-black`}
              style={
                isColorValid(postCategory.secondaryColor)
                  ? {
                      backgroundColor: postCategory.secondaryColor,
                    }
                  : { borderColor: 'black', borderWidth: '2px' }
              }
            >
              {title ? (
                <div
                  className="px-4 py-2 md:flex-1 md:px-8 md:py-4"
                  style={
                    isColorValid(postCategory.primaryColor)
                      ? {
                          backgroundColor: postCategory.primaryColor,
                        }
                      : null
                  }
                >
                  <h3 className="text-3xl font-light leading-normal list-none md:text-4xl">
                    <Appear>{title}</Appear>
                  </h3>
                </div>
              ) : null}
              <div className="p-4 md:p-8">
                <div className="mb-4 font-bold text-normal">
                  <Appear>
                    <Html text={text} />
                  </Appear>
                </div>
                <div>
                  <Appear>
                    <Button style="outlineOnly" title={ctaText} href={ctaUrl} />
                  </Appear>
                </div>
              </div>
            </div>
          </Grid>
        </Container>
      </Spacer>
    )
  }

  return content
}

export default DesignJet8
