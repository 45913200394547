// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Appear from './Appear'

type PostCategory = {
  primaryColor: string,
  secondaryColor: string,
  title: string,
}

type Props = {
  title: string,
  postCategory: PostCategory,
  noPaddingBottom: boolean,
}

const BlogBlock1 = ({ title, postCategory, noPaddingBottom = false }: Props): React$Element<"div"> => {
  const isColorValid = (color) => {
    if (!color) return false
    return new RegExp('^#[0-9a-fA-F]{6}$').test(color) 
  }

  let paddingBottom = 'pb-2'
  if (postCategory) {
    paddingBottom = 'pb-8 md:pb-16'
  }
  if (noPaddingBottom) {
    paddingBottom = ''
  }
  
  return (
    <div className={`${paddingBottom}`}>
      {postCategory.title ? <div className='py-4' style={isColorValid(postCategory.primaryColor) ? { backgroundColor: postCategory.primaryColor } : { }}>
        <Container>
          <Grid>
            <div className="col-span-4 col-start-1 md:col-span-8 md:col-start-3">
              <h1 className="text-left text-big3"><Appear>{ postCategory.title }</Appear></h1>
            </div>
          </Grid>
        </Container>
      </div> : null}
      <div className='py-4 md:py-10' style={isColorValid(postCategory.secondaryColor) ? { backgroundColor: postCategory.secondaryColor } : { }}>
        <Container>
          <Grid>
            <div className="col-span-4 col-start-1 md:col-span-8 md:col-start-3">
              <h1 className="text-left text-big4"><Appear>{title}</Appear></h1>
            </div>
          </Grid>
        </Container>
      </div>
    </div>
  )
}

export default BlogBlock1