// @flow

import React from 'react'
import Container from './Container'
import LazyImage from './LazyImage'
import Spacer from './Spacer'
import Grid from "./Grid"
import { useLiterals } from 'store/'
import type { ApiImage } from '../types'
import Html from './Html'
import Button from './Button'
import useIsMobile from '../hooks/useIsMobile'

type Item = {
  id: string,
  image: ApiImage,
  title: string,
  teaser: string,
  url: string,
}
type Props = {
  items: Array<Item>,
  title: string,
  category: string,
}

const PostSlider = ({ items, title, category }: Props) => {
  const isMobile = useIsMobile()

  return (
    <Spacer top bottom smaller>
      <Container>
        <Grid>
          <div className="col-span-12 col-start-1 md:col-start-3 md:col-span-8">
            <div className="relative flex items-start justify-between w-full pb-8 md:pb-10 md:items-center md:justify-center md:block">
              <h3 className="w-full text-3xl border-t-2 border-b-2 py-3 md:py-4 md:text-3xl md:w-full">{title}</h3>
            </div>
            <Grid>
              {items.map(
                (
                  {
                    id,
                    image,
                    title,
                    teaser,
                    url,
                  },
                  index
                ) => (
                  <a
                    key={id}
                    id={`recommended-${index}`}
                    className="col-span-4 w-full h-full bg-sand flex flex-row md:flex-col relative"
                    href={isMobile ? url : null}
                  >
                    <div className='w-1/2 md:w-full'>
                      <a href={url} className="w-full max-h-full">
                        <LazyImage image={image} alt={title} cover isSquare />
                      </a>
                    </div>
                    <div className='w-1/2 md:w-full md:flex-grow flex flex-col leading-6 md:leading-none'>
                      {category ? <div className="px-4 md:px-6 py-2 text-black border-black border-b-2 text-small md:text-normal">
                        <p className="w-full">{category}</p>
                      </div> : null}
                      <div className="p-4 md:p-6 flex flex-col flex-grow justify-between">
                        <div>
                          <div className='mb-4'>
                            <a href={url} className="select-none text-20px md:text-28px text-black">
                              <Html text={title} />
                            </a>
                          </div>
                          <div className="hidden md:block mb-4 mr-4 font-light select-none text-normal">
                            <Html text={teaser} />
                          </div>
                        </div>
                        <div className='hidden md:block'>
                          <Button to={url} style='outlineOnly' title={useLiterals('read_more')} />
                        </div>
                      </div>
                    </div>                    
                  </a>
                )
              )}
            </Grid>
          </div>
        </Grid>
      </Container>
    </Spacer>
  )
}

export default PostSlider
