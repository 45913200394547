// @flow

import React from 'react'
import SimpleHeaderImage from './SimpleHeaderImage'
import type { ApiImage } from 'types/'

type Props = {
  title: string,
  image: ApiImage,
  imageAlt: string,
  type?: string,
  childAfterMenu?: Boolean
}

const StoryBlock0 = ({ image, title, type, imageAlt, childAfterMenu }: Props) => {
  return (
    <div className={`bg-white ${childAfterMenu ? 'mt-menu-mobile md:mt-menu-desktop' : ''}`}>
      {<SimpleHeaderImage type="stories" big={type !== 'post'} image={image} title={title} postType={type} taller={true} alt={imageAlt} />}
    </div>
  )
}

export default StoryBlock0
