/* eslint-disable react/jsx-no-target-blank */
// @flow

// Dependencies
import React, { useState, useEffect } from "react"

// Hooks
import { useLiterals } from "../store"

// Libraries/Helpers
import { post, getter } from "../lib/fetch"
import validateEmail from "../lib/validateEmail"
import getAllUrlParams from '../lib/getAllUrlParams'

// Components
import Container from "./Container"
import Grid from "./Grid"
import Spacer from "./Spacer"
import { Formik } from "formik"
import Input from "./Input"
import Select from "./Select"
import TextArea from "./TextArea"
import Checkbox from "./Checkbox"
import Html from "./Html"
import ReCAPTCHA from "react-google-recaptcha"
import PrintersSelect from "./PrintersSelect"
import { getCookie } from "../lib/cookies"
import LinkExternalHP from './LinkExternalHP'


const CommunicationCheck = ({ setValue, value, literal, error, label1, label2 }) => {
  return (
    <div className="flex justify-between my-2">
      <Html text={useLiterals(literal)} />
      <div className="flex">
        <div className="flex items-center justify-end flex-1">
          <span className="px-2 font-light">{label1}</span>
          <div className="cursor-pointer" onClick={() => setValue(1)}>
            <Checkbox error={error} status={value === 1} />
          </div>
        </div>
        <div className="flex items-center justify-end flex-1">
          <span className="px-2 font-light">{label2}</span>
          <div className="cursor-pointer" onClick={() => setValue(0)}>
            <Checkbox error={error} status={value === 0} />
          </div>
        </div>
      </div>
    </div>
  )
}

type Industry = {
  id: string,
  title: string,
  formRef: string,
}

type CompanySize = {
  value: string,
  label: string,
}

type Props = {
  text: string,
  text1: string,
  text2: string,
  text3: string,
  text4: string,
  text5: string,
  textYes: string,
  textNo: string,
  printerTypes: any,
  phoneNumber: string,
  text4Url: string,
  text6: string,
  text6Url: string,
  postUrl: string,
  language: string,
  type: string,
  industries: Array<Industry>,
  timeframe: Array<CompanySize>,
  companySizes: Array<CompanySize>,
  bmt: string,
  redirect: string,
  region: string,
  shouldFindRecipients: boolean,
  shouldFindRecipientsUrl: string,
  countries: Array<string>,
}

const ContactForm = ({
  text,
  text5,
  text6,
  textYes,
  textNo,
  text6Url,
  printerTypes,
  language,
  postUrl,
  type,
  companySizes,
  timeframe,
  industries,
  bmt,
  redirect,
  region,
  shouldFindRecipients,
  shouldFindRecipientsUrl,
  countries,
}: Props): React$Node => {
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    busPhone: "",
    company: "",
    timeframetoBuy1: undefined,
    industry1: "",
    jobTitle1: "",
    country: "",
    masterEmailOptin1: undefined,
    masterPhoneOptin1: undefined,
    masterMailOptin1: undefined,
    hpReseller1: "",
    topicOfInterest: undefined,
  })

  const [solvedCaptcha, setSolvedCaptcha] = useState(false)
  const [sent, setSent] = useState(false)
  const [ready, setReady] = useState(false)
  const [selectedPrinters, setSelectedPrinters] = useState([])
  const [inIframe, setInIframe] = useState(false)
  const [sourcePage, setSourcePage] = useState(false)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get("iniframe")) {
      setInIframe(true)
    }
    setSourcePage(sessionStorage.getItem("sourcePage"))
  }, [])

  useEffect(() => {
    if (printerTypes.length) {
      initialValues.topicOfInterest = ""
    }
    if (type === "contact-expert") {
      delete initialValues.topicOfInterest
    }
    if (type !== "free-sample-kit" && ["en_US", "fr_CA"].includes(language)) {
      // initialValues.timeframetoBuy1 = timeframe[0].value
    }
    if (type === "free-sample-kit" || !["en_US", "fr_CA"].includes(language)) {
      delete initialValues.timeframetoBuy1
    }

    const urlParams = new URLSearchParams(window.location.search)
    if (
      typeof urlParams.has("option") !== "undefined" &&
      printerTypes.length > 0
    ) {
      if (
        typeof printerTypes[parseInt(urlParams.get("option")) - 1] !==
        "undefined"
      ) {
        const item = printerTypes[parseInt(urlParams.get("option")) - 1]
        initialValues.topicOfInterest = [`${item.id}-${item.title}`]
      }
    }

    if (
      typeof urlParams.has("p") !== "undefined" &&
      printerTypes.length > 0 &&
      urlParams.get("p")
      ) {
        const partsPrinters = urlParams.get("p").split(',')
        let itemFound = false
        printerTypes.forEach(printer => {
          const itemId = printer.id.toString()
          if (!itemFound && (itemId == partsPrinters || partsPrinters.includes(itemId))) {
            setSelectedPrinters([itemId])
            itemFound = true
          }
        })
    }

    setInitialValues({ ...initialValues })
    setReady(true)
  }, [])

  const getIndustries = () => {
    return industries.map(industry => {
      return {
        value: industry.formRef,
        label: industry.title,
      }
    })
  }  

  return (
    <div className="relative text-black bg-white">
      <Spacer top bottom small>
        <Container>
          <Grid>
            <div className="col-span-4 md:col-span-6 md:col-start-1 md:row-start-1">
              <p className="font-light">
                <Html text={text} />
              </p>
            </div>
            {!sent && ready ? (
              <Formik
                initialValues={initialValues}
                validate={values => {
                  const errors = {}
                  Object.keys(values).forEach(field => {
                    const allowed = [
                      "hpReseller1",
                      "comment",
                      "jumpid",
                      "zipPostal",
                      "bMTActivityIDMostRecent1",
                      "interactionType",
                      "notificationField1",
                      "pageField",
                      "elqFormName",
                      "elqSiteId",
                      "elqCampaignId",
                      "timeframetoBuy1"
                    ]
                    if (allowed.indexOf(field) === -1) {
                      if (field === "emailAddress") {
                        if (!validateEmail(values[field])) {
                          errors[field] = true
                        }
                      } else if (
                        values[field] === "" ||
                        values[field] === "-1" ||
                        values[field] === undefined
                      ) {
                        errors[field] = true
                      } else if (field === "industry1" && !values[field]) {
                        errors[field] = true
                      }
                    }
                  })

                  return errors
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    const recipientsRes = shouldFindRecipients
                      ? await getter(shouldFindRecipientsUrl, {
                          type,
                          language: values.country,
                        })
                      : {}

                    const data = values
                    data.sourcePage1 = sourcePage || "unknown"
                    data.jumpid = ""
                    data.bMTActivityIDMostRecent1 = bmt
                    data.notificationField1 = recipientsRes.recipients || ""
                    data.pageField = `${type} ${language} ${window.location.href}`

                    data.elqFormName =
                      "FMVFGSBWWHQ-Q220-GSB3475-25720-virtual-booth-embed"
                    data.elqSiteId = "704062726"
                    data.elqCampaignId = ""

                    const jumpid = getCookie('jumpid')
                    if (jumpid !== null) {
                      data.jumpid = jumpid                    
                      data.comment = `jumpid: ${data.jumpid} // comment: ${data.comment}`
                    } else {
                      const params = getAllUrlParams(window.location.href)
                      if (typeof params['jumpid'] !== 'undefined') {
                        data.jumpid = params['jumpid']                    
                        data.comment = `jumpid: ${params['jumpid']} // comment: ${data.comment}`
                      }
                    }

                    const bmtid = getCookie('bmtid')
                    if (bmtid !== null) {
                      data.bMTActivityIDMostRecent1 = bmtid                    
                      data.comment = `bmtid: ${data.bMTActivityIDMostRecent1} // comment: ${data.comment}`
                    } else {
                      const params = getAllUrlParams(window.location.href)
                      if (typeof params['bmtid'] !== 'undefined') {
                        data.bMTActivityIDMostRecent1 = params['bmtid']                    
                        data.comment = `bmtid: ${params['bmtid']} // comment: ${data.comment}`
                      }
                    }

                    data.masterPhoneOptin1 = data.masterPhoneOptin1 ? 'Yes' : 'No'
                    data.masterEmailOptin1 = data.masterEmailOptin1 ? 'Yes' : 'No'
                    data.masterMailOptin1 = data.masterMailOptin1 ? 'Yes' : 'No'

                    const selectedPrinter = printerTypes.filter(printer => printer.id === selectedPrinters[0])[0]
                    if (selectedPrinter && selectedPrinter.sku) {
                      const sku = selectedPrinter.sku
                      data.productInterest1MostRecent1 = sku
                    }

                    const response = await post(postUrl, data)
                    if (response) {

                      window.dataLayer = window.dataLayer || []  
                      window.dataLayer.push({
                        event: 'e_contactSubmit',
                        linkPlacement: 'content',
                        linkID: type,
                      })

                      setSubmitting(false)
                      setSent(true)
                      window.scroll(0, 0)
                      if (inIframe) {
                        setTimeout(() => {
                          if (typeof redirect !== "undefined") {
                            window.history.back()
                          }
                        }, 1000)
                      } else {
                        setTimeout(() => {
                          if (typeof redirect !== "undefined") {
                            window.location.href = redirect
                          }
                        }, 10000)
                      }
                    }
                  } catch (err) {
                    console.log("Error with form", err)
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  setFieldValue,
                  isSubmitting,
                }) => {
                  return (
                    <form
                      onSubmit={handleSubmit}
                      className="col-span-4 gap-4 md:col-start-7 md:col-span-6 md:gap-8"
                    >
                      <div className="w-full gap-4 md:grid md:grid-cols-2 md:gap-8">
                        {printerTypes.length ? (
                          <div className="col-span-2 mb-2 md:mb-0 md:col-span-2">
                            <PrintersSelect
                              error={errors.topicOfInterest}
                              items={printerTypes}
                              selectedPrinters={selectedPrinters}
                              setSelectedPrinters={printersArray => {
                                setSelectedPrinters(printersArray)

                                const topicsOfInterest = printersArray.map(id => {
                                  const item = printerTypes.find(item => Number(item.id) === Number(id))
                                  return `${item.id}-${item.title}`
                                })

                                setFieldValue(
                                  "topicOfInterest",
                                  topicsOfInterest.join()
                                )
                              }}
                              isMulti={region === "APJ"}
                            />
                          </div>
                        ) : null}
                        <Input
                          error={errors.firstName && touched.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                          id="firstName"
                          className="col-span-2 mb-2 md:mb-0 md:col-span-1"
                          label={useLiterals("first_name")}
                        />
                        <Input
                          error={errors.lastName && touched.lastName}
                          onChange={handleChange}
                          value={values.lastame}
                          onBlur={handleBlur}
                          id="lastName"
                          className="col-span-2 mb-2 md:mb-0 md:col-span-1"
                          label={useLiterals("last_name")}
                        />
                        <Select
                          error={errors.country && touched.country}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.country}
                          items={countries}
                          id="country"
                          className="col-span-2 mb-2 md:mb-0 md:col-span-1"
                          label={useLiterals("country_region")}
                        />
                        <Input
                          error={errors.company && touched.company}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.company}
                          id="company"
                          className="col-span-2 mb-2 md:mb-0 md:col-span-1"
                          label={useLiterals("company")}
                        />
                        <Input
                          error={errors.emailAddress && touched.emailAddress}
                          onChange={handleChange}
                          value={values.emailAddress}
                          onBlur={handleBlur}
                          id="emailAddress"
                          className="col-span-2 mb-2 md:mb-0 md:col-span-1"
                          label={useLiterals("email_address")}
                        />
                        <Input
                          error={errors.busPhone && touched.busPhone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.busPhone}
                          id="busPhone"
                          className="col-span-2 mb-2 md:mb-0 md:col-span-1"
                          label={useLiterals("phone")}
                        />
                        <Select
                          error={errors.industry1 && touched.industry1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.industry1}
                          items={getIndustries()}
                          id="industry1"
                          className="col-span-2 mb-2 md:mb-0 md:col-span-1"
                          label={useLiterals("industry")}
                        />
                        <Input
                          error={errors.jobTitle1 && touched.jobTitle1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.jobTitle1}
                          id="jobTitle1"
                          className="col-span-2 mb-2 md:mb-0 md:col-span-1"
                          label={useLiterals("jobTitle")}
                        />
                        {type === "free-sample-kit" && (
                          <>
                            <Input
                              error={errors.address1 && touched.address1}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.address1}
                              id="address1"
                              className="col-span-2 mb-2 md:mb-0 md:col-span-1"
                              label={useLiterals("delivery_address1")}
                            />
                          </>
                        )}
                        {region === "AMAS" && (
                          <Select
                            error={
                              errors.timeframetoBuy1 && touched.timeframetoBuy1
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.timeframetoBuy1}
                            items={timeframe}
                            id="timeframetoBuy1"
                            className="col-span-2 mb-2 md:mb-0 md:col-span-1"
                            label={useLiterals("timeframe")}
                            required={false}
                          />
                        )}
                        {region === "AMAS" && (
                          <Input
                            error={errors.zipPostal && touched.zipPostal}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.zipPostal}
                            id="zipPostal"
                            className="col-span-2 mb-2 md:mb-0 md:col-span-1"
                            label={useLiterals("zipPostal")}
                            required={false}
                          />
                        )}
                        <TextArea
                          error={errors.comment && touched.comment}
                          onChange={handleChange}
                          value={values.comment}
                          onBlur={handleBlur}
                          id="comment"
                          className="col-span-2 mb-2 md:mb-0 md:col-span-2"
                          label={useLiterals("how_can_we_help")}
                        />
                        {region === "AMAS" && (
                          <Input
                            error={false}
                            onChange={handleChange}
                            value={values.hpReseller1}
                            onBlur={handleBlur}
                            id="hpReseller1"
                            label={useLiterals("reseller")}
                            className="col-span-2 mb-2 md:mb-0 md:col-span-1"
                            required={false}
                          />
                        )}
                        <span className="col-span-2 font-light">
                          <LinkExternalHP href={text6Url} target="_blank">
                            <Html text={useLiterals("terms") || ""} />
                          </LinkExternalHP>
                        </span>
                        {useLiterals("hp_comunications")?.trim().length > 0 &&
                          <p className="col-span-2 mt-4 md:col-span-2">
                            {useLiterals("hp_comunications")}
                          </p>
                        }
                        {useLiterals("hp_comunications_explain")?.trim().length > 0 &&
                          <p className="col-span-2 font-light md:col-span-2">
                            {useLiterals("hp_comunications_explain")}
                          </p>
                        }
                        <div className="col-span-2">
                          <div className="mb-2 md:mb-0">
                            <CommunicationCheck
                              error={
                                errors.masterEmailOptin1 &&
                                touched.masterEmailOptin1
                              }
                              literal="accept_email"
                              value={values.masterEmailOptin1}
                              id="masterEmailOptin1"
                              setValue={value => {
                                setFieldValue("masterEmailOptin1", value)
                              }}
                              label1={textYes}
                              label2={textNo}
                            />
                          </div>
                          <div className="mb-2 md:mb-0">
                            <CommunicationCheck
                              error={errors.masterMailOptin1 && touched.masterMailOptin1}
                              literal="accept_postal_mail"
                              value={values.masterMailOptin1}
                              setValue={value => {
                                setFieldValue("masterMailOptin1", value)
                              }}
                              label1={textYes}
                              label2={textNo}
                            />
                          </div>
                          <div className="mb-2 md:mb-0">
                            <CommunicationCheck
                              error={errors.masterPhoneOptin1 && touched.masterPhoneOptin1}
                              literal="accept_phone"
                              value={values.masterPhoneOptin1}
                              setValue={value => {
                                setFieldValue("masterPhoneOptin1", value)
                              }}
                              label1={textYes}
                              label2={textNo}
                            />
                          </div>
                        </div>
                        {text6Url && text6 && (
                          <div className="col-span-2 font-light">
                            <LinkExternalHP href={text6Url} target="_blank">
                              <Html text={text6} />
                            </LinkExternalHP>
                          </div>
                        )
                        }
                        <div
                          className={`mt-2 justify-self-center md:justify-self-start md:col-span-2 ${
                            solvedCaptcha ? "" : ""
                          }`}
                        >
                          <ReCAPTCHA
                            onChange={() => setSolvedCaptcha(true)}
                            sitekey="6Le8-8gaAAAAAExf2tBEpZBFSToT9p0hK9YM4gJh"
                            size="compact"
                            theme="light"
                          />
                        </div>
                        <button
                          data-name={`submit-${type}`}
                          disabled={isSubmitting || !solvedCaptcha}
                          type="submit"
                          className={`w-full inline-block h-12 col-span-2 mt-4 text-white bg-black border border-black outline-none appearance-none md:col-span-1 ${
                            isSubmitting || !solvedCaptcha
                              ? "opacity-50 cursor-default"
                              : "md:hover:border-black md:hover:text-black md:hover:bg-white"
                          }`}
                        >
                          {isSubmitting
                            ? useLiterals("sending")
                            : useLiterals("submit")}
                        </button>
                      </div>
                    </form>
                  )
                }}
              </Formik>
            ) : (
              <p className="col-span-4 md:col-span-6">{text5}</p>
            )}
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default ContactForm
