// @flow

import React from 'react'
import Html from './Html'
import Spacer from './Spacer'
import Container from './Container'
import Grid from './Grid'
import Appear from './Appear'

type Props = {
  title: string,
  supertitle: string,
  text: string,
}

const StoryBlock2 = ({ title, supertitle, text }: Props) => {
  return (
    <Spacer>
      <Container>
        <Grid>
          <div
            className={`col-span-4 col-start-1 md:col-start-3 md:col-span-8 mt-8`}
          >
            {supertitle ? <Appear>
              <h3 className={`mb-8 text-big1`}>{supertitle}</h3>
            </Appear> : null}
            {title ? <Appear>
              <h2 className={`mb-8 text-big2`}>{<Html text={title} />}</h2>
            </Appear> : null}
            {text ? <Appear>
              <div className={`mb-8 font-light text-big2`}>
                <Html text={text} />
              </div>
            </Appear> : null}
          </div>
        </Grid>
      </Container>
    </Spacer>
  )
}

export default StoryBlock2
