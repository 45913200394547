// @flow

import React, { useState, useEffect } from 'react'
import Spacer from './Spacer'
import Container from './Container'
import Grid from './Grid'
import PrinterShoppableItem from './PrinterShoppableItem'
import { useLiterals } from 'store/'

type Props = {
  title?: String,
  items: any,
  parentName: string,
  postSlug?: string,
}

function chunk(arr, bulkSize = 20) {
  const bulks = []
  for (let i = 0; i < Math.ceil(arr.length / bulkSize); i++) {
      bulks.push(arr.slice(i * bulkSize, (i + 1) * bulkSize))
  }
  return bulks
}

const PrinterShoppable = ({
  title,
  items,
  parentName,
  postSlug,
  darkBackground,
}: Props) => {

  const [apiItems, setApiItems] = useState(undefined)
  const url = useLiterals('price_api_url')

  const fetchData = async () => {
    try {
      const res = await fetch(url)
      const text = await res.text() 
      const data = await new window.DOMParser().parseFromString(text, 'text/xml')
      let items = Array.from(data.getElementsByTagName('item'))

      let pageType = '' 
      if (window.location.href.includes('/printer/')) {
        pageType = '-productpage'
      } else if (window.location.href.includes('/blog/')) {
        pageType = '-blogpost'
      }

      items = items.map(item => {    

        let fetchBuyURL = item.getElementsByTagName('g:link')[0].textContent
        if (postSlug && postSlug !== '') {
          fetchBuyURL = `${fetchBuyURL}${fetchBuyURL.includes('?') ? '&' : '?'}utm_source=largeformat${pageType}&utm_medium=referral&utm_campaign=largeformat-printerfeed&utm_content=${postSlug}`
        }

        return {
          ref: item.getElementsByTagName('g:id')[0].textContent,
          title: item.getElementsByTagName('g:title')[0].textContent,
          text: item.getElementsByTagName('g:description')[0].textContent,
          price: item.getElementsByTagName('g:price')[0].textContent || null,
          anchorPrice: typeof item.getElementsByTagName('g:sale_price') !== 'undefined' && item.getElementsByTagName('g:sale_price').length > 0 ? item.getElementsByTagName('g:sale_price')[0].textContent || null : null,
          image: item.getElementsByTagName('g:image_link')[0].textContent,
          inStock: item.getElementsByTagName('g:availability')[0].textContent,
          buyURL: fetchBuyURL,
        }
      })
      setApiItems(items)
    } catch (e) {
      console.log('Error fetching products', e)
    }
  }

  useEffect(() => {
    if (url) fetchData()
  }, [])

  const gridElements = apiItems ? items && items.length && items.filter(item => {
    const apiItem = apiItems.find(i => i.ref === item.hpRef)
    return !!apiItem
  }).map(item => {
    const apiItem = apiItems.find(i => i.ref === item.hpRef)
    return (
      <div key={item.ref + 'api'} className={`col-span-4  mb-4 ${parentName === 'CategoryWrapper' ? 'md:col-span-6 lg:col-span-4' : 'sm:col-span-4 md:col-span-4'}`}>
        <PrinterShoppableItem
          item={apiItem}
        />
      </div>
    )
  }) : items && items.length && items.map(item => {
    return (
      <div key={item.ref + 'manager'} className={`col-span-4 ${darkBackground ? 'text-white border-white' : '' }  mb-4 ${parentName === 'CategoryWrapper' ? 'md:col-span-6 lg:col-span-4' : 'sm:col-span-4 md:col-span-4'}`}>
        <PrinterShoppableItem
          item={item}
          darkBackground={darkBackground}
        />
      </div>
    )
  })

  if (
    !gridElements ||
    !gridElements.length ||
    (gridElements.length && gridElements.filter(i => i !== null).length === 0)
  ) {
    return null
  }

  const content = (
    <div className="grid grid-cols-4 gap-4 sm:grid-cols-8 md:grid-cols-12">
      {chunk([...gridElements], 3).map((row, index) => {
        return (<div key={index} className="grid grid-cols-4 col-span-4 gap-4 sm:col-span-8 md:col-span-8 md:col-start-3 sm:grid-cols-8 md:grid-cols-12">
          {row}
        </div>)
      })}
    </div>
  )

  if (parentName === 'CategoryWrapper') {
    return (
      <Spacer top bottom>
        <Container className="relative">
          <Grid>
            <div className="col-span-4 col-start-1 md:col-span-9 md:col-start-4 md:mb-0">
              {content}
            </div>
          </Grid>
        </Container>
      </Spacer>
    )
  }

  return (
    <Spacer top bottom small>
      <Container className="relative">
        <div className="grid w-full grid-cols-4 gap-4 pb-8 sm:grid-cols-8 md:grid-cols-12">
          <h3 className="w-full col-span-4 gap-4 py-4 text-xl border-t-2 border-b-2 sm:col-span-8 md:col-span-8 md:col-start-3 md:text-3xl md:w-full">{title || 'Printers'}</h3>
        </div>
        {content}
      </Container>
    </Spacer>
  )
}

export default PrinterShoppable
