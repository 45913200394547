// @flow

import React from 'react'
import type { Node } from 'react'
import ModuleIterator from './ModuleIterator'
import Spacer from './Spacer'
import Container from './Container'
import BlogSocial from './BlogSocial'

type Props = {
  lateral: Array<any>,
  contentLeft: Node,
  childAfterMenu: boolean
}

const PostWrapper = ({ children, childAfterMenu }: Props) => {

  return (
    <div className={`relative leading-8 text-larger ${childAfterMenu ? 'mt-menu-mobile md:mt-menu-desktop' : ''}`}>
      {/* {lateral && (
        <div className="z-50 my-20 md:mt-0 md:absolute md:top-0 md:bottom-0 md:left-0 md:right-0 md:pointer-events-none">
          <div className="sticky" style={{ top: '7rem'}}>
            <Container>
              <div className="gap-4 pt-1 md:grid md:grid-cols-12">
                <div className="col-span-2 pt-8 lg:mr-4 md:pointer-events-auto text-larger md:pt-12">
                  <ModuleIterator modules={lateral} />
                </div>
              </div>
            </Container>
          </div>
        </div>
      )} */}
      <div className="z-0 bg-white">
        <div className="m-0">
          {children}
        </div>
      </div>
    </div>
  )
}

export default PostWrapper
